<template>
  <div class="NotificationsMSTeams" v-if="user.subscription_plan.apps_alerts">
    <div class="top">
      <div class="title">
        Microsoft Teams Notifications
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <m-s-teams :monitor="monitor"
                                :general="general"
                                @update="$emit('update')" />
      </div>
    </div>
  </div>
  <div v-else>
    <div class="upgrade">
      <UpgradeBadge text="Microsoft Teams is available only for paid customers."/>
    </div>
  </div>
</template>

<script>
import MSTeams from '@/components/Monitor/Notifications/MSTeamsWebhook/MSTeams.vue'
import UpgradeBadge from '@/components/Common/UpgradeBadge.vue'

export default {
  components: { UpgradeBadge, MSTeams },

  metaInfo: {
    title: 'MS Teams Notifications'
  },

  props: {
    monitor: {
      required: false,
      type: Object
    },
    general: {
      required: false,
      default: false,
      type: Boolean
    }
  },

  computed: {
    ...mapState('authentication', ['user'])
  }
}
</script>

<style lang="scss" scoped>
.NotificationsMSTeams {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .title {
      font-size: 24px;
      font-weight: 400;
      line-height: 1.5;
      color: #444;
    }
  }
}
.upgrade {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  @media (max-width: 767px) {
    margin: 24px 0;
    min-height: unset !important;
  }
}
</style>
